<template>
  <div class="wrap">
    <div class="nav">
      <div class="navList">
        <div @click="jump('1')">
          <span>首页</span>
          <span>Home</span>
        </div>
        <div @click="jump('2')">
          <span>服务案例</span>
          <span>Case</span>
        </div>
        <div @click="jump('3')" class="cur">
          <span>新闻资讯</span>
          <span>News</span>
        </div>
        <div @click="jump('4')">
          <span>业务伙伴</span>
          <span>Cooperation</span>
        </div>
        <div @click="jump('5')">
          <span>关于我们</span>
          <span>About</span>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="title">
        <p>会议室装修预算怎么样做</p>
        <p>发布日期：2021-05-18</p>
      </div>
      <div class="text">
        <div>
          会议室装修请专业团队是非常有必要的，除了环境本身面积大小要考虑，还要结合会议室的实际功能来确定。请专业团队，做出合理设计，确保专业施工，这样就可以建立起专业实用的基础。这其中突出实用性的重点，要有功能的实用性考虑，会议室装修预算合理是关键。
        </div>
        <img src="../../public/imgs/news/text5.png" alt="" />
        <div>
          装修服务于功能实用，还必须要考虑的就是设计这个重点，功能的实用性。会议室环境在装修的过程中，必须要考虑功能特点来进行整体的布局，请专业团队，做好设计，花费一定的费用，也是非常有必要的。所以会议室装修合理预算的内容当中，功能性突出是重点。
        </div>
        <div>
          装修合理，预算科学，会议室这个特殊的公共环境，在实践的施工与装修设计方面，会有总价的标准，会为实现更好的设计服务提供可能。这其中会议室装修的总价多少，是预算合理与否的关键。结合整体的总价，做出预算平衡，专业服务团队，要有专业标准，不超上限为原则。
        </div>
        <img src="../../public/imgs/news/text6.png" alt="" />

        <div>
          各种规格的会议室环境，要在进行装修的过程中，找准其实用的功能，确保合理的工程建设水平，还必须要充分考虑的就是装修预算本身，要符合具体施工的要求，打好基础。会议室装修做好预算，明确目标重点，就是可以达到科学装修效果的关键，预算合理有所侧重很有必要。
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/Footer";
export default {
  name: "index",
  components: {
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    jump(index) {
      if (index == 1) {
        this.$router.push({
          path: "/",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/case",
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/news",
        });
      }
      if (index == 4) {
        this.$router.push({
          path: "/partner",
        });
      }
      if (index == 5) {
        this.$router.push({
          path: "/me",
        });
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wrap {
  height: 100vh;
  width: 1920px;
  margin: 0 auto;
}
.cur {
  color: #028ccd !important;
}
.nav .navList {
  position: absolute;
  top: 25px;
  right: 300px;
  height: 50px;
}
/* 导航 */
.nav {
  width: 100%;
  height: 100px;
  background: #fff url("../../public/imgs/indexNew/logo.png") no-repeat 76px
    center;
  overflow: hidden;
  cursor: pointer;
  position: fixed;
  top: 0;
  z-index: 8888;
}
.navList span {
  display: block;
}
.navList div {
  text-align: center;
  float: left;
  line-height: 50px;
  height: 70px;
  width: 70px;
  margin-right: 45px;
  font-size: 16px;
  color: #666666;
  position: relative;
  top: 0;
  transition: top 0.5s;
}
.navList div span:nth-of-type(1) {
  opacity: 1;
  transition: all 0.5s;
}
.navList div span:nth-of-type(2) {
  opacity: 0;
  transition: all 0.5s;
}
.navList div:hover {
  top: -50px;
}
.navList div:hover span:nth-of-type(1) {
  opacity: 0;
}
.navList div:hover span:nth-of-type(2) {
  opacity: 1;
}
.main {
  width: 1920px;
  margin: 100px auto 50px;
}
.title {
  background: #eee;
  height: 150px;
  overflow: hidden;
}
.title p {
  width: 1200px;
  margin: 0 auto;
  text-align: left;
}
.title p:nth-of-type(1) {
  font-size: 30px;
  color: #1f1f1f;
  margin-top: 46px;
}
.title p:nth-of-type(2) {
  line-height: 34px;
  font-size: 12px;
  color: #a0a0a0;
}

.text {
  width: 1200px;
  margin: 0 auto;
}
.text div {
  font-size: 14px;
  color: #5b5b5b;
  line-height: 24px;
  margin-top: 70px;
}
.text img {
  width: 100%;
}
</style>
